<template>
  <div brand>
    <visual2 tit="BRAND" txt="안심할 수 있는 철에 대한<br> <b>현대제철</b>의<br class='mo'> 한결 같은 고민 속에 탄생한<br> <i class='kv-logo'></i>" bg="brand-kv"/>
    <Tab :list="selectList" :active="active" @select="select"/>
    <article>
      <Paragraph class="content about" v-if="active === 'about'">
        <div class="top">
          <p class="para"><i></i>는<br> 어떠한 환경에서도<br class="mo"> 안심할 수 있는 기술력으로<br> <b>늘 기본을 지킵니다</b></p>
          <div class="vid para">
            <div class="v-inner">
              <iframe src="https://www.youtube.com/embed/_JFsGtDTSEg?rel=0&playsiline" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div class="cont cont1">
          <h3 class="para">뛰어난 기술력과 차별화된 제품 경쟁력을 갖춘<br> 프리미엄 건설용 강재<br> <i class="vk-logo"></i><b>가 약속하는 기본</b></h3>
          <ul>
            <li class="para" v-for="(basic, idx) in basicList" :key="basic.id" :class="'basic'+(idx+1)">
              <div class="icon"></div>
              <p class="tit" v-html="basic.tit"></p>
              <p class="txt" v-html="basic.txt"></p>
            </li>
          </ul>
        </div>
        <div class="cont cont2">
          <h3 class="para">지속적인 연구개발과<br> 고도화된 기술력을 보장하는</h3>
          <p class="tit para"><i class="vk-logo"></i><br class="mo"> PRODUCT</p>
          <ul>
            <li class="para" v-for="(prd, idx) in prdList" :key="prd.id" :class="'prod'+(idx+1)">
              <div class="c-bg"></div>
              <div class="inner">
                <p class="txt" v-html="prd.nm"></p>
                <p class="txt-over" v-html="prd.txt"></p>
              </div>
              <RouterLink :to="prd.link" class="prd1 para"></RouterLink>
            </li>
          </ul>
        </div>
        <div class="cont cont3">
          <h3 class="para">탁월한 제품력으로 다양한 산업군에 활용되는<br> <b>당신 곁의 </b><i class="vk-logo"></i></h3>
          <ul>
            <li class="para" v-for="(record, idx) in recordList" :key="record.id" :class="'record'+(idx+1)">
              <div class="p-img"></div>
              <p class="nm" v-html="record.nm"></p>
            </li>
          </ul>
        </div>
      </Paragraph>

      <Paragraph class="content history" v-if="active === 'history'">
        <div class="top-tit para">
          모두가 안심하고 살 수 있는<br class="mo"> 대한민국을 만들기 위해<br>
          꾸준한 연구개발과 제품 출시를 이어온<br>
          <b>현대제철의 프리미엄 건설용 강재,</b><br>
          <i class="vk-logo"></i>의 스토리를 소개합니다.
        </div>
        <ul>
          <li v-for="(history, idx) in historyList" :key="history.id" :class="'history'+(idx+1)">
            <p class="year para" v-html="history.year"></p>
            <div class="h-kv para"></div>
            <div class="cont">
              <p class="tit para" v-html="history.tit"></p>
              <p class="txt para" v-html="history.txt"></p>
            </div>
          </li>
        </ul>
        <div class="bott">
          <div class="b-logo para"></div>
          <p class="tit para">기본을 지킵니다</p>
          <p class="txt para">끊임없는 연구 개발과 기본에 충실한 자세로<br> 대한민국의 중심에서 안심하고 살 수 있는<br class="mo"> 세상을 만들어 갑니다 </p>
        </div>
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual2 from "@/views/components/Visual2";
import Tab from "@/views/components/Tab";
import Paragraph from "@/views/components/Paragraph";
import brand from "@/data/brand";

export default {
  name: "Brand",
  components: {Paragraph, Visual2, Tab},
  data() {
    return {
      scrollY: 0,
      winH: 0,
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    active() {
      return this.$route.params.brand
    },
    selectList() {
      return [
        {key: 'about', label: 'About H CORE'},
        {key: 'history', label: 'H CORE History'}
      ]
    },
    basicList() {
      return brand.basicList;
    },
    prdList() {
      return brand.prdList;
    },
    recordList() {
      return brand.recordList;
    },
    historyList() {
      return brand.historyList;
    }
  },
  methods: {
    select(brand) {
      if (this.active === brand) return;
      this.$router.push({ params: { brand } })
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[brand] {
  article { .bgc(#fff); .rel;
    .content { .p(50,0,190); .-box; overflow: hidden;
      > div { .pt(140); .-box; }
      .top { .tc;
        p { .fs(48); .lh(68); .ggo; .mb(100);
          i { .wh(266,57); .contain('/images/mo/logo-blue.png'); .ib; vertical-align: sub; .mr(8); }
          b { .fs(72); .lh(90); .bold; .mt(10); .ib; }
        }
        .vid { .rel; .pb(32vw); .-box;
          .v-inner { .wh(93%,45.7vw); .mh-c; .contain('/images/mo/brand-vid.png'); .mh-c; box-shadow: 0 25px 30px 10px rgba(0,0,0,0.2);
            iframe { .f; }
          }
          &:after { .cnt; .wh(100vw,67.3vw); .contain('/images/mo/brand-bg.png'); .abs; .lt(0,10vw); z-index: -1; }
        }
      }
      .cont { .tc;
        h3 { .fs(36); .lh(48); .ggo; .regular;
          .vk-logo { .wh(207,42); .contain('/images/mo/logo-blue.png'); .ib; }
          b { .fs(60); .lh(76); .bold; .mt(20); .ib; }
        }
      }
      .cont1 {
        h3 { .mb(60); }
        ul { .flex; .space-between; .flex-wrap; .wf; .p(0,3%); .-box;
          li { .ib; .wh(48.5%,523); overflow: hidden; .mb(3vw); .rel; .tc;
            .icon { .wh(340,142); .ib; }
            .tit { .fs(54); .lh(70); .roboc; .bold; color:#999; .ls(0);
              b { color:#333; }
            }
            .txt { .fs(22); .lh(36); .spoqa; .mt(30); .ls(-0.025em); }
            &.basic1 { .cover('/images/mo/br-bg1.png');
              .icon { .contain('/images/mo/icon1.png'); }
            }
            &.basic2 { .cover('/images/mo/br-bg2.png');
              .icon { .contain('/images/mo/icon2.png'); }
            }
            &.basic3 { .cover('/images/mo/br-bg3.png');
              .icon { .contain('/images/mo/icon3.png'); }
            }
            &.basic4 { .cover('/images/mo/br-bg4.png');
              .icon { .contain('/images/mo/icon4.png'); }
            }
          }
        }
      }
      .cont2 { .rel; .pt(435); .-box; .h(1772); .mt(150);
        &:after { .cnt; .wh(100%,1454); .abs; .lt; .bgc(#f2f2f2);}
        h3, .tit {  z-index: 3; .rel; }
        .tit { .fs(80); .lh(80); .ggo; .mt(25);
          .vk-logo { .wh(266,57); .contain('/images/mo/logo-blue.png'); .ib; }
        }
        ul {
          li { .ib; z-index: 2; .abs;
            .c-bg { .f; .ib; }
            .inner { .abs; .f; pointer-events: none;
              .txt { .fs(40); .lh(42); .ggo; color:#c0c0c0; .bold; .abs;
                s { .fs(30); .lh(34); .roboc; .block; .regular; .mt(5); .nowrap; }
                i { .wh(50,14); .contain('/images/mo/ico-brand-arrow.png'); .ib; .mb(10); }
              }
              .txt-over { .fs(18); .lh(24); .hide; }
            }
            a { .pointer; .f; .abs; .lt; }
            &.prod1 { .wh(323,536); .tr; .lt;
              .c-bg { .contain('/images/mo/prd1.png'); }
              .inner { .rt;
                .txt { .rt(70,50); }
              }
            }
            &.prod2 { .wh(451,313); .tl; .rt;
              .c-bg { .contain('/images/mo/prd2.png'); }
              .inner {  .lb;
                .txt { .lb(100,0); }
              }
            }
            &.prod3 { .wh(564,586); .tl; .lt(0,658);
              .c-bg { .contain('/images/mo/prd3.png'); }
              .inner { .rb;
                .txt { .rb(230,-30); }
              }
            }
            &.prod4 { .wh(285,437); .tl; .rb(0,319);
              .c-bg { .contain('/images/mo/prd4.png'); }
              .inner { .lt;
                .txt { .lt(100,-100); }
              }
            }
            &.prod5 { .wh(412,444); .tl; .lb(0,30);
              .c-bg { .contain('/images/mo/prd5.png'); }
              .inner { .rb;
                .txt { .rb(0,120); }
              }
            }
          }
        }

      }
      .cont3 { .m(0,3%); .-box; .pt(140); .-t(#0a293e);
        h3 {
          b { .mt(10); }
          .vk-logo { .wh(266,57); .ml(10); .ib; vertical-align: sub; }
        }
        ul { .flex; .space-between; .flex-wrap; .wf; .mt(60);
          li { .ib; .wh(48.5%,38.8vw); overflow: hidden; .rel; .tc;
            .nm { .fs(24); .lh(40); .ggo; .o(0.9); }
            .p-img { .wh(100%,29vw); .ib; }
            &.record1 .p-img { .contain('/images/mo/c3-list1.png') }
            &.record2 .p-img { .contain('/images/mo/c3-list2.png') }
            &.record3 .p-img { .contain('/images/mo/c3-list3.png') }
            &.record4 .p-img { .contain('/images/mo/c3-list4.png') }
            &.record5 .p-img { .contain('/images/mo/c3-list5.png') }
            &.record6 .p-img { .contain('/images/mo/c3-list6.png') }
          }
        }
      }
      &.history { .tl; .pb(0);
        .top-tit { .fs(48); .lh(72); .tc; .ggo;
          .vk-logo { .wh(206,40); .contain('/images/mo/logo-blue.png'); .ib; .vam; .mb(3); .mr(-10); }
        }
        ul { .p(0,3%); .wf; .-box; .mt(100);
          li { .wf; .rel; .p(25,0,100); .-box;
            &:after { .cnt; .wh(2,80%); .bgc(#d5d5d5); .abs; .lb(65,0); }
            .year { .fs(100); .lh(120); .robo; .light; .tl; .pl(50); .ls(-0.05em); .mb(26); }
            .h-kv { .wh(100%,230); .rel; z-index: 1; }
            .cont { .p(25,0,0,105); .-box; .spoqa; .tl;
              .tit { .fs(30); .lh(40); .medium; color:#2a5f7c; .ls(-0.025em);
                s { .ib; .mr(10); color:#333; }
              }
              .txt { .fs(24); .lh(36); .mt(15); .keep-all; .ls(-0.025em); }
            }
            &.history1 .h-kv { .cover('/images/mo/his1.png'); .bg-c; }
            &.history2 .h-kv { .cover('/images/mo/his2.png'); .bg-c; }
            &.history3 .h-kv { .cover('/images/mo/his3.png'); .bg-c; }
            &.history4 .h-kv { .cover('/images/mo/his4.png'); .bg-c; }
            &.history5 .h-kv { .cover('/images/mo/his5.png'); .bg-c; }
            &.history6 {
              .h-kv { .cover('/images/mo/his6.png'); .bg-c; }
              .cont .tit s { .block; }
            }
            &.history7 {
              .h-kv { .cover('/images/mo/his7.png'); .bg-c; }
              .cont .tit s { .block; }
            }
            &.history8 .h-kv { .cover('/images/mo/his8.png'); .bg-c; }
          }
        }
        .bott { .bgc(#f7f7f7); .p(210,0,170); .-box; .tc; .ggo;
          .b-logo { .wh(266,57); .contain('/images/mo/logo-blue.png'); .ib; }
          .tit { .fs(72); .lh(90); .bold; .mt(10); }
          .txt { .fs(36); .lh(48); .mt(30); }
        }
      }
    }
  }
}
@media screen and(min-width:760px) {
  [brand] article .content .cont2 a.prd3 { .l(50%); .ml(-30%); }
}
@media screen and(min-width:1024px) {
  [brand] {
    article {
      .content {
        .top {
          .vid { .pb(210);
            .v-inner { .wh(1206,592); .contain('/images/pc/brand-vid.png'); box-shadow: 0 25px 30px 10px rgba(0,0,0,0.3); }
            &:after { .cnt; .wh(100%,506); .cover('/images/pc/brand-bg.png'); .abs; .lt(0,297); }
          }
        }
        .cont { .tc;
          h3 { .fs(48); .lh(60);
            b { .fs(72); .lh(90); .mt(10); }
            .vk-logo { .wh(266,57); .ib; .mr(5); .mb(-4); }
          }
        }
        .cont1 {
          h3 { .mb(90); }
          ul { .p(0); .w(1400); .mh-c;
            li {  .wh(340,523); .mb(0);
              .txt { .fs(20); .mt(40); }
            }
          }
        }
        .cont2 { .pt(315); .h(1131); .mt(160); .bgc(#f2f2f2); overflow: hidden;
          &:after { .hide; }
          h3, .tit {  z-index: 4; .rel; }
          ul {
            li {
              .c-bg { transition: transform 0.3s; pointer-events: none; }
              .inner {
                .txt {
                  s { .fs(30); }
                  i { .m(0); .pt(10); }
                }
                .txt-over { .fs(18); .lh(24); .ls(-0.05em); .hide; }
                a { z-index: 3; }
              }
              &.prod1 { .wh(36vw,29.8vw); .tl;
                .c-bg { .contain('/images/pc/prd1.png'); }
                .inner { .rt;
                  .txt { .rt(30,65); }
                }
              }
              &.prod2 { .wh(31.25vw,21.6vw);
                .c-bg { .contain('/images/pc/prd2.png'); }
                .inner {  .lb;
                  .txt { .lt(150,260); }
                }
              }
              &.prod3 { .wh(34.8vw,26.4vw); .lt(50%,580); .ml(-150);
                .c-bg { .contain('/images/pc/prd3.png'); }
                .inner { .lb;
                  .txt { .lt(30,350); }
                }
              }
              &.prod4 { .wh(21vw,25.6vw); .rb;
                .c-bg { .contain('/images/pc/prd4.png'); .mt(80); }
                .inner { .lt(0,-20);
                  .txt { .lt; }
                }
              }
              &.prod5 { .wh(645,527); .lb;
                .c-bg { .contain('/images/pc/prd5.png'); }
                .inner { .rt;
                  .txt { .rt(30,20);
                    i { .abs; .rb(-70,0); }
                  }
                }
              }
              &:hover {
                .c-bg {transform: scale(1.1); }
                .inner {
                  .txt { color:#333;
                    i { .hide; }
                  }
                  .txt-over { .ib; .abs;
                    i { .wh(50,14); .contain('/images/mo/ico-brand-arrow-over.png'); .ib; }
                  }
                }
                &.prod1 .inner .txt-over { .rt(-230,73);
                  i { .abs; .rb(0,5); }
                }
                &.prod2 .inner .txt-over { .lt(150,355);
                  i { .abs; .lb(0,-30); }
                }
                &.prod3 .inner .txt-over { .lt(30,450);
                  i { .abs; .lb(0,-30); }
                }
                &.prod4 .inner .txt-over { .lt(110,0);
                  i { .abs; .rb(0,5); }
                }
                &.prod5 .inner .txt-over { .rt(-160,180);
                  i { .abs; .lb(0,-30); }
                }
              }
            }
          }


        }
        .cont3 { .m(0); .pt(160); .-t;
          h3 .vk-logo { .mb(3); }
          ul { .w(1400); .mh-c; .mt(105);
            li { .wh(442,350);
              .p-img { .wh(442,265); }
              p { .fs(24); .mt(0); }
              &.record1 .p-img { .contain('/images/mo/c3-list1.png') }
              &.record2 .p-img { .contain('/images/mo/c3-list2.png') }
              &.record3 .p-img { .contain('/images/mo/c3-list3.png') }
              &.record4 .p-img { .contain('/images/mo/c3-list4.png') }
              &.record5 .p-img { .contain('/images/mo/c3-list5.png') }
              &.record6 .p-img { .contain('/images/mo/c3-list6.png') }
            }
          }
        }
        &.history {
          ul { .p(0); .mt(180);
            li { .w(1400); .p(25,0,100); .mh-c; .tc;
              &:after { .cnt; .wh(2,75%); .lb(210,0); }
              .year { .lh(100); .pl(0); .mb(0); .ib; .vat; .w(325); .tl; }
              .h-kv { .wh(700,230); .ib; .vat; }
              .cont { .p(25,0,0,510);
                .txt { .fs(20); .lh(30); .mt(25); }
              }
              &.history6 .cont .tit s, &.history7 .cont .tit s { .ib; }
            }
          }
          .bott { .bgc(#f7f7f7); .p(210,0,170); .-box; .tc; .ggo;
            .b-logo { .wh(266,57); .contain('/images/mo/logo-blue.png'); .ib; }
            .tit { .fs(72); .lh(90); .bold; .mt(10); }
            .txt { .fs(36); .lh(48); .mt(30); }
          }
        }
      }
    }
  }
}
@media (prefers-color-scheme: dark) {
  [brand] article .content .cont2:after { .bgc(#f2f2f2)!important; }
}
</style>
