<template>
  <div paragraph>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Paragraph",
  data() {
    return {
      scrollY: 0,
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.initIntersectionObserver();
  },
  updated() {
    this.initIntersectionObserver();
  },
  methods: {
    handleScroll() {
      this.initIntersectionObserver();
      this.scrollY = window.pageYOffset || document.documentElement.scrollTop;
    },
    initIntersectionObserver() {
      const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.1) {
            setTimeout(() =>{
              entry.target.classList.add('on');
            },300);
          }
        })
      });
      const boxSti = document.querySelectorAll('.para:not(.on)');
      boxSti.forEach((el) => {
        io.observe(el);
      });
    },
  }
}
</script>
<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[paragraph] {
  .para { transform: translateY(100px); opacity: 0; transition: transform 0.3s, opacity 0.3s; transition-timing-function: ease-out;
    &.on { transform: translateY(0); opacity: 1; }
  }
}
@media screen and(min-width:1024px) {
}
</style>