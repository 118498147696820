export default {
  basicList: [
    {tit:'<b>C</b>aring', txt: '고객이 쉬는 집, 여행가는 길,<br> 일터인 산업 시설.<br> 어디에서나 안심할 수 있도록<br>세심하고 정교한 기술력에<br> 프리미엄하고 감성적인<br> 브랜드 경험을 전달합니다'},
    {tit:'<b>O</b>ptimal', txt: '생산 공정, 기술력 향상 뿐만 아니라<br> 최적화된 의사결정을 이끄는<br>  Smart Enterprise를 통해<br> 보다 스마트하고 혁신적으로<br> 고객의 비즈니스를<br class="mo"> 성공으로 이끕니다'},
    {tit:'<b>R</b>ecover', txt: '자연 상태의 철에서 완성품<br> 다시 재활용 원료인<br> 철 스크랩에 이르는<br> 친환경 자원순환형 시스템을 통해<br> 자연과 미래 사회를 책임지며<br> 지속 가능한 지구를 만듭니다'},
    {tit:'<b>E</b>ssential', txt: '건축, 토목, 플랜트, 에너지 등<br> 건설 영역의 핵심 브랜드로<br class="mo"> 나아가며,<br class="web"> 가장 믿을 수 있는<br> 최고 품질의 제품을 만듭니다'}
  ],
  prdList: [
    {nm:'강관 <s>STEEL PIPE</s> <i></i>', txt:'다양한 규격(직경, 두께, 재질)과 <br> 뛰어난 품질로 극한의 하중을<br> 견뎌냅니다 <i></i>', link:'/product#prod4'},
    {nm:'철근 <s>REINFOECING BAR</s> <i></i>', txt:'D10~D57의 폭넓은 규격과 고강도, 용접용,<br> 내진용, 극저온용 등 다양한 강종이 제공됩니다<i></i>', link:'/product#prod2'},
    {nm:'열연/냉연 <s>HR/CR</s> <i></i>', txt:'어떠한 환경과 복잡한 가공에도<br> 품질과 성능을 발휘하는 철강제품의 꽃 입니다.<i></i>', link:'/product#prod5'},
    {nm:'후판 <s>PLATE</s> <i></i>', txt:'6mm 이상의 두꺼운 철강재로<br> 저온, 고온, 부식 등 외부 환경에<br> 잘 버텨냅니다<i></i>', link:'/product#prod3'},
    {nm:'형강 <s>SECTION</s> <i></i>', txt:'강도의 신뢰성과 충격 흡수능력이 뛰어나<br> 구조물의 주요 구조재료로 널리 사용됩니다 <i></i>', link:'/product#prod1'}
  ],
  recordList: [
    {nm:'|  반포주공 아파트 1단지  |'},
    {nm:'|  잠실 롯데월드타워  |'},
    {nm:'|  남극 장보고 기지  |'},
    {nm:'|  송도 G-TOWER  |'},
    {nm:'|  IFC 서울  |'},
    {nm:'|  킨텍스 제2전시관  |'}
  ],
  historyList: [
    {year:'2005', tit: '<s>건축구조용 형강</s> SHN490 개발', txt: '전기로 분야에서의 독보적인 기술을 바탕으로<br> 국내 최초로 건축구조용 형강 개발에 성공, 건축물과<br class="mo"> 사용자의 안전 확보에 기여했습니다.'},
    {year:'2010', tit: '<s>건축구조용 후판</s> SN 생산', txt: 'H형강 뿐만 아닌 대형, 초고층 구조물에 사용되는 후판까지<br> 건축구조용 전용 강재 생산을 확대했습니다.'},
    {year:'2014', tit: '<s>건축구조용 강관</s> SNT 생산', txt: '하이스코와 합병을 이루며 강관 제품으로까지<br class="mo"> 포트폴리오를 확대,<br class="web"> 다양한 규격과 우수한 품질의 제품을<br> 생산해 오고 있습니다.'},
    {year:'2016', tit: '<s>내진용 철근</s> SD500S-SD600S 개발', txt: '국내 최초로 SD500 및 SD600급 내진용 철근에 대한<br class="mo"> 개발 및 양산체제 구축 완료,<br class="web"> 제품의 항복강도 상한치 항복비 등 강재성능의 신뢰도를 높인 제품을 선보여왔습니다.'},
    {year:'2017', tit: 'H CORE 론칭', txt: '현대제철은  ‘대한민국을 안전하게 만들어 나아가는<br> 중심(CORE)이 되겠다’는 의지를 담아 H CORE 브랜드를 론칭했습니다.<br><br>현대제철의 기술력을 바탕으로 개발된 H CORE는<br> 지금까지도 대한민국 안전의 중심을 지키고 있습니다.'},
    {year:'2020', tit: '<s>건축구조용 일반 형강</s> SHN275/355 ㄱ형강/ㄷ형강 인증 취득 <s>고강도 건축구조용 일반형강</s> SHN420 ㄱ형강 KS 인증취득 <s>고강도 건축구조용 H형강</s> SHN460 개발', txt: '대한민국 건설 안전을 위한 건축구조용 강재 포트폴리오<br class="mo"> 확대에 꾸준한 노력을 기울여<br class="web"> ㄱ형강, ㄷ형강, 고강도 형강까지<br class="mo"> 그 범위를 늘렸습니다.'},
    {year:'2021', tit: '<s>건축구조용 강관</s> SNT275/355 KS 인증 취득', txt: '국내 최초로 모든 제조방법의 건축구조용 강관에 대해<br class="mo"> KS 인증을 취득하며<br class="web"> 현대제철 H CORE의 높은 품질을<br class="mo"> 인정받았습니다.<br><br> ERW와 SAW로 제작된 다양한 사이즈의<br class="mo"> 건축구조용 강관(SNT)을 모두 공급할 수 있는<br> 유일한 제조사, 현대제철은 지속적인 연구 개발로<br class="mo"> 시장을 확대해 나가고 있습니다.'},
    {year:'2022', tit: '<s>H CORE 리론칭</s>', txt: 'H CORE가 내진용 강재를 넘어<br> 프리미엄 건설용 강재로 발돋움 했습니다.<br><br> 새로운 H CORE는 내진용 강재를 넘어<br> 토목, 건축, 플랜트, 에너지 등 건설 전 분야 공정에 적용할 수 있도록<br> 제품 포트폴리오를 확대했으며,<br> 고성능, 고품질 제품에만 선별적으로 적용하여<br> 대한민국 프리미엄 건설용 강재로 자리매김 할 것입니다.'}
  ]
}